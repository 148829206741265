import React from 'react';
import { NextPage } from 'next';
import Link from 'next/link';
import {
  infos,
  Layout,
  routePath,
  useAuth,
  withRedirectRoute,
} from '@typevid/ui-shared';
import clsx from 'clsx';

export const Login: NextPage = () => {
  const {
    login,
    authActionData: {
      login: { loading, error },
    },
    isAuthenticated,
    isLoading,
  } = useAuth();
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const emailElement = e.currentTarget.elements['email'];
    const passwordElement = e.currentTarget.elements['password'];
    login(emailElement.value, passwordElement.value);
  };

  return (
    <Layout title="Sign in" header={null}>
      <div className="min-h-screen flex items-center justify-center -mt-16 py-16 px-4 sm:px-6 lg:px-8">
        <div
          className={clsx(
            'max-w-md w-full space-y-8',
            !isLoading && !isAuthenticated ? 'block' : 'hidden'
          )}
        >
          <div>
            <svg
              className="mx-auto h-12 w-auto text-indigo-600"
              viewBox="0 0 500 500"
              xmlns="http://www.w3.org/2000/svg"
              aria-label={`${infos.siteName} Logo`}
            >
              <rect width="500" height="500" rx="7%" fill="currentColor" />
              <path
                d="M278.039 390.078H222.18V166.055H138V110H362.023V166.055H278.039V390.078Z"
                fill="white"
              />
            </svg>

            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              Sign in to your account
            </h2>
            <p className="mt-2 text-center text-sm text-gray-600">
              Or
              <Link href={routePath.signup}>
                <a
                  href={routePath.signup}
                  className="font-medium text-indigo-600 hover:text-indigo-500"
                >
                  {' '}
                  Create new one
                </a>
              </Link>
            </p>
          </div>
          <form
            className="mt-8 space-y-6"
            method="POST"
            onSubmit={handleSubmit}
          >
            <input type="hidden" name="remember" value="true" />
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Email address *"
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  minLength={8}
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Password *"
                />
              </div>
            </div>

            <div className="flex items-center justify-between">
              <div className="text-sm">
                <a
                  href="#forgot-password"
                  className="font-medium text-indigo-600 hover:text-indigo-500"
                >
                  Forgot your password?
                </a>
              </div>
            </div>

            {error && (
              <ul>
                {error.graphQLErrors.map(({ message }, i: number) => {
                  if (typeof message === 'object') {
                    return (message as unknown as string[]).map(
                      (msg: string, j: number) => (
                        <li key={j} className="text-xs text-red-600">
                          {msg}
                        </li>
                      )
                    );
                  }
                  return (
                    <li className="text-xs text-red-600" key={i}>
                      {message}
                    </li>
                  );
                })}
              </ul>
            )}

            <div>
              <button
                type="submit"
                className={clsx(
                  'group relative w-full flex justify-center py-2 px-4 border border-transparent text-white text-sm font-medium rounded-md',
                  'hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
                  loading
                    ? 'bg-indigo-700 text-opacity-50 cursor-default'
                    : 'bg-indigo-600'
                )}
                disabled={loading}
              >
                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  {loading ? (
                    <svg
                      className="animate-spin h-5 w-5 text-indigo-500"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  ) : (
                    <svg
                      className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  )}
                </span>
                Sign in
              </button>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default withRedirectRoute(Login);
